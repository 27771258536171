import {useContext} from "react";
import {IntlContext} from "../utility/context/Internationalization";

export const STORE_ID = "storeId"
export const BUSINESS_ID = "businessId"
export const USER_ID = "userId"
export const CURRENCY = "currency"
export const JWT_TOKEN = "jwtToken"
export const REACT_APP_BASE_URL = "https://torapos-live.herokuapp.com"
// export const REACT_APP_BASE_URL = "http://localhost:8085"
export const DEFAULT_IMAGE = "https://firebasestorage.googleapis.com/v0/b/torapos-5133c.appspot.com/o/back.png?alt" +
    "=media&token=02ad3e0d-4feb-4e30-8b03-172a0ab0dbdf"

const now = new Date // now

now.setHours(0)
now.setMinutes(0)
now.setSeconds(0)
now.setMilliseconds(0)

export const START_OF_TODAY = now.getTime()

now.setHours(23)   // set hours to 0
now.setMinutes(59) // set minutes to 0
now.setSeconds(59) // set seconds to 0
now.setMilliseconds(59) // set seconds to 0

export const END_OF_TODAY = now.getTime()

const date = new Date();
export const START_OF_MONTH = new Date(date.getFullYear(), date.getMonth(), 1, 6, 0, 0).getTime();
