import React, {createContext, useContext, useState} from 'react';
import {START_OF_MONTH} from '@src/const/Constants';
import {IntlContext} from '@src/utility/context/Internationalization';

const FilterContext = createContext(null);

const FilterContextProvider = ({children}) => {

    const {messages} = useContext(IntlContext)

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1).getTime());
    const [endDate, setEndDate] = useState(new Date().getTime());
    const [dateRangeTitle, setDateRangeTitle] = useState(messages["thisYear"])
    const [refreshData, setRefreshData] = useState(false);
    const [showDateRanges, setShowDateRanges] = useState(false);
    const filterSate = {

        startDate: startDate,
        setStartDate: setStartDate,
        endDate: endDate,
        setEndDate: setEndDate,
        dateRangeTitle: dateRangeTitle,
        setDateRangeTitle: setDateRangeTitle,
        refreshData: refreshData,
        setRefreshData: setRefreshData,
        showDateRanges: showDateRanges,
        setShowDateRanges: setShowDateRanges
    }

    return (
        <FilterContext.Provider value={filterSate}>
            {children}
        </FilterContext.Provider>
    )
}

export {FilterContextProvider, FilterContext};
